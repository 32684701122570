import { type Maybe } from '~/contentful/graphql/_generated/graphql';

/**
 * {@link Maybe} is the internal "optional" type that our GraphQL codegen uses to make fields
 * nullable. By extending it, we can centralize this check.
 */
export type Optional<T> = Maybe<T>;

export function isNonOptional<T>(value: Optional<T>): value is T {
  return value !== null;
}

export function filterNonOptional<T>(array: Optional<Array<Optional<T>>>): Array<T> {
  return array?.filter(isNonOptional) || [];
}
