export type AspectRatio = number & {
  __type: 'AspectRatio';
};

export function getAspectRatio({ width, height }: { width: number; height: number }): AspectRatio {
  return (width / height) as AspectRatio;
}

export function getOrientation(aspectRatio: AspectRatio): 'portrait' | 'landscape' {
  return aspectRatio < 1 ? 'portrait' : 'landscape';
}
