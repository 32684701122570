import type { ImageLoaderProps } from 'next/image';

export const imageLoader = ({ src, width }: ImageLoaderProps): string => {
  const encodedSrc = encodeURI(src);
  const format = encodedSrc.endsWith('.svg') ? '' : 'fm=webp';

  const widthParam = encodedSrc.includes('?')
    ? `&w=${width}&${format}&q=90`
    : `?w=${width}&${format}&q=90`;

  return `${encodedSrc}${widthParam}`;
};
