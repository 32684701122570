'use client';

import NextImage, { type ImageProps as NextImageProps } from 'next/image';
import { useContext, useEffect, useState } from 'react';

import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { ColumnContext } from '~/ui/components/grid';
import { getImageAspectRatio } from '~/ui/modules/image/getImageAspectRatio';
import { type AspectRatio, getAspectRatio, getOrientation } from '~/ui/utils/aspectRatio';
import { getImagePlaceholder } from '~/v1/components/image/image';
import type { ImageColSpan } from '~/v1/components/image/image.interface';
import { getSizesFromSpan } from '~/v1/components/image/image.utils';

import styles from './image.module.scss';
import { imageLoader } from './loader';
import { Image_AssetFragment } from './query';

export interface ImageProps extends Omit<NextImageProps, 'src' | 'alt'> {
  image: FragmentType<typeof Image_AssetFragment>;

  onCalculateAspectRatio?: (aspectRatio: AspectRatio) => void;
  aspectRatio?:
    | React.CSSProperties['aspectRatio']
    | { portrait: string; landscape: string }
    | { portrait?: string; landscape: string }
    | { portrait: string; landscape?: string };
  colSpan?: ImageColSpan;
}

export function Image({
  image,
  className,
  onLoad,
  aspectRatio: aspectRatioOverride,
  onCalculateAspectRatio,
  colSpan,
  children,
  ...rest
}: ImageProps) {
  const columns = useContext(ColumnContext);
  const { src, title, width, height } = getFragmentData(Image_AssetFragment, image);
  const [aspectRatio, setAspectRatio] = useState(() => getImageAspectRatio(image));

  useEffect(() => {
    if (aspectRatio) {
      onCalculateAspectRatio?.(aspectRatio);
    }
  }, [onCalculateAspectRatio, aspectRatio]);

  return (
    <div
      className={cx(styles.imageContainer, className)}
      style={{
        aspectRatio: aspectRatio
          ? determineAspectRatioToApply(aspectRatio, aspectRatioOverride)
          : undefined,
      }}
    >
      <NextImage
        src={src ?? ''}
        alt={title ?? ''}
        fill
        placeholder={
          src?.endsWith('.svg') ? undefined : getImagePlaceholder(width ?? 200, height ?? 200)
        }
        loader={imageLoader}
        onLoad={event => {
          const { naturalHeight, naturalWidth } = event.currentTarget;
          // aspectRatio is a primitive and won't cause a re-render if the values are the same
          setAspectRatio(getAspectRatio({ width: naturalWidth, height: naturalHeight }));
          onLoad?.(event);
        }}
        sizes={getSizesFromSpan(colSpan || columns)}
        {...rest}
      />
      {children}
    </div>
  );
}

/**
 * Determines which aspect ratio to apply to the image. `aspectRatioOverride`
 * has precedence over `naturalAspectRatio`, if defined.
 */
function determineAspectRatioToApply(
  naturalAspectRatio: AspectRatio,
  aspectRatioOverride?: ImageProps['aspectRatio'],
): React.CSSProperties['aspectRatio'] {
  if (!aspectRatioOverride) {
    return naturalAspectRatio;
  }

  if (getOrientation(naturalAspectRatio) === 'portrait') {
    return typeof aspectRatioOverride === 'object'
      ? aspectRatioOverride.portrait
      : aspectRatioOverride;
  } else {
    return typeof aspectRatioOverride === 'object'
      ? aspectRatioOverride.landscape
      : aspectRatioOverride;
  }
}
