import { graphql } from '~/contentful/graphql';

export const Image_AssetFragment = graphql(`
  fragment Image_AssetFragment on Asset {
    src: url
    title
    width
    height
  }
`);
