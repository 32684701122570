'use client';

import { useState } from 'react';

import { Slot } from '@radix-ui/react-slot';
import cx from 'classnames';

import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { Column, Grid } from '~/ui/components/grid';
import { useBreakpoint } from '~/ui/components/grid/useBreakpoint';
import { Header_AssetFragment } from '~/ui/modules/header/query';
import { Image } from '~/ui/modules/image';
import { getImageAspectRatio } from '~/ui/modules/image/getImageAspectRatio';
import { Breakpoint } from '~/ui/styles/grid';
import { getOrientation } from '~/ui/utils/aspectRatio';
import { CreditSection, type Credits } from '~/v1/modules/header/creditSection';
import { Preheader } from '~/v1/modules/header/preheader';
import { Breadcrumb } from '~/v1/system/breadcrumb';

import styles from './header.module.scss';

export interface HeaderModuleProps {
  title: string;
  description?: string;
  eyebrow?: string;
  quickLinks?: React.ReactNode;
  credits?: Credits;
  rawDateCredit?: boolean;
  preheaderAction?: React.ReactNode;
  image?: FragmentType<typeof Header_AssetFragment>;
  /**
   * Determines the amount of spacing after the header
   * @default false
   * @example
   *  /events
   *  /news
   */
  isLandingPage?: boolean;
  /**
   * Determines the size of the title
   * @default false
   * @example
   *  /history
   *  /mission
   */
  isRootPage?: boolean;
}

export function HeaderModule({
  title,
  description,
  eyebrow,
  quickLinks,
  credits,
  rawDateCredit,
  preheaderAction,
  image: imageData,
  isLandingPage = false,
  isRootPage = false,
}: HeaderModuleProps) {
  const image = imageData ? getFragmentData(Header_AssetFragment, imageData) : undefined;
  const isSmallBp = useBreakpoint(Breakpoint.SM);
  const [aspectRatio, setAspectRatio] = useState(() =>
    image ? getImageAspectRatio(image) : undefined,
  );
  const hasPortraitImage = aspectRatio ? getOrientation(aspectRatio) === 'portrait' : undefined;

  const titleClassName = isRootPage ? 'pageTitleLargeTypography' : 'pageTitleSmallTypography';
  const topTextClass = eyebrow ? 'bodyLargeTypography' : titleClassName;

  const imageProps = hasPortraitImage
    ? {
        colSpan: {
          [Breakpoint.SM]: 5,
          [Breakpoint.MD]: 4,
          [Breakpoint.LG]: 5,
        },
        aspectRatio: '4/5',
      }
    : {
        colSpan: {
          [Breakpoint.SM]: 5,
          [Breakpoint.MD]: 7,
          [Breakpoint.LG]: 9,
        },
        aspectRatio: '3/2',
      };

  return (
    <Grid>
      <Column sm={5} md={7} lg={9}>
        <header
          className={cx(styles.root, topTextClass, {
            [styles.isLandingPage]: isLandingPage,
            [styles.portraitImage]: hasPortraitImage,
          })}
        >
          <Preheader left={<Breadcrumb />} right={preheaderAction} className={styles.preheader} />
          <div className={styles.spacer} />
          {eyebrow && <div className={cx(styles.eyebrow, 'bodyLargeTypography')}>{eyebrow}</div>}
          <div className={styles.titleAndDescription}>
            <h1 className={titleClassName}>{title}</h1>
            {description && (
              <p className={cx(styles.description, 'modulesTitleTypography')}>{description}</p>
            )}
          </div>
          {quickLinks && (
            <Slot className={cx(styles.quickLinks, 'overflowRight')}>{quickLinks}</Slot>
          )}
          {credits && (
            <CreditSection
              credits={credits}
              rawDate={rawDateCredit}
              className={styles.creditSection}
            />
          )}
          {image && (
            <div
              className={cx(styles.image, 'overflowRight', {
                overflowLeft: !hasPortraitImage || isSmallBp,
              })}
            >
              <Image
                image={image}
                priority
                onCalculateAspectRatio={setAspectRatio}
                {...imageProps}
              />
              {image.description && (
                <Column
                  offsetLeft={{
                    lg: hasPortraitImage ? 0 : 1,
                  }}
                  md={hasPortraitImage ? undefined : 5}
                  lg={hasPortraitImage ? undefined : 5}
                  className={styles.caption}
                >
                  <span className="captionTypography">{image.description}</span>
                </Column>
              )}
            </div>
          )}
        </header>
      </Column>
    </Grid>
  );
}
