import { type FragmentType, getFragmentData } from '~/contentful/graphql';
import { isNonOptional } from '~/contentful/utils/optional';
import { Image_AssetFragment } from '~/ui/modules/image/query';
import { getAspectRatio } from '~/ui/utils/aspectRatio';

export function getImageAspectRatio(image: FragmentType<typeof Image_AssetFragment>) {
  const { width, height } = getFragmentData(Image_AssetFragment, image);
  if (isNonOptional(width) && isNonOptional(height)) {
    return getAspectRatio({ width, height });
  }
}
